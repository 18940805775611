@import "../../../resources/styles/variables";

@font-face {
  font-family: "fontoctopus";
  src: url("./fontoctopus.eot");
  src: url("./fontoctopus.eot#iefix") format("embedded-opentype"), url("./fontoctopus.woff") format("woff"),
    url("./fontoctopus.ttf") format("truetype"), url("./fontoctopus.svg") format("svg");
  font-weight: @normal;
  font-style: normal;
}

.fontoctopus-octopus {
  display: inline-block;
  font: normal normal normal 1em fontoctopus; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}

.fontoctopus-octopus:before {
  content: "\e800";
}
