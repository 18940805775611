@screen-sm: 37.4rem; //600px
@screen-md: 79.9rem; //1279px
@drawerBreakpointWidth: 56.25rem; //900px;

@media (max-width: @screen-sm) {
  .hide-sm {
    display: none !important;
  }
}

@media (min-width: @screen-sm) {
  .visible-sm {
    display: none !important;
  }
}

@media (max-width: @screen-md) {
  .hide-md {
    display: none !important;
  }
}
@media (min-width: @screen-md) {
  .visible-md {
    display: none !important;
  }
}
