@import "../../../resources/styles/variables";

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Regular.ttf");
  font-weight: @normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Italic.ttf");
  font-weight: @normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Light.ttf");
  font-weight: @light;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Medium.ttf");
  font-weight: @semibold;
}

@font-face {
  font-family: "Roboto";
  src: url("./Roboto-Bold.ttf");
  font-weight: @bold;
}
