@import "../../../app/resources/styles/variables";
@import "../../../app/resources/styles/media";

.paper {
  box-sizing: border-box;
  background: white;
  box-shadow: var(--shadowMedium);
  border-radius: 8px;
  padding: 24px;
  font-size: 14px;
  max-width: 328px;

  p {
    margin-top: 0;
    margin-bottom: 16px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: @screen-md) {
  .paper::after {
    display: none;
  }
}

.arrow {
  display: block;
  width: 100%;
  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 10px solid white;
    border-color: white white transparent transparent;
    background: white;
    transform-origin: 0 0;
    box-shadow: -4px 4px 4px 0rem rgba(0, 0, 0, 0.04);
  }
}

.popper {
  z-index: 9999;
  &[data-popper-placement*="top"] .arrow::after {
    bottom: -20px;
    left: 4px;
    margin-left: 33px;
    transform: rotate(-45deg);
    box-shadow: -4px 4px 4px 0rem rgba(0, 0, 0, 0.04);
  }
  &[data-popper-placement*="top-end"] .arrow::after {
    left: calc(100% - 96px);
  }
  &[data-popper-placement*="right"] .arrow::after {
    top: -47px;
    left: 0px;
    margin-top: 33px;
    transform: rotate(45deg);
    box-shadow: -4px 4px 4px 0rem rgba(0, 0, 0, 0.04);
  }
  &[data-popper-placement*="bottom"] .arrow::after {
    top: 0px;
    left: 3px;
    margin-left: 63px;
    transform: rotate(135deg);
  }
  &[data-popper-placement*="top"] .arrow {
    height: 100%;
  }
  &[data-popper-placement*="bottom-end"] .arrow::after {
    left: calc(100% - 96px);
  }
  &[data-popper-placement*="left"] .arrow::after {
    top: 16px;
    right: -20px;
    transform: rotate(-135deg);
    box-shadow: -4px 4px 4px 0rem rgba(0, 0, 0, 0.04);
  }
}

.globalContainer {
  display: inline-block;
}

.absolute {
  position: relative;
  z-index: 10;
}

.iconContainer {
  cursor: pointer;
}

.circle {
  display: flex;
}

.infoIcon {
  color: #145586;
  height: 12px;
  font-size: 12px;
}
