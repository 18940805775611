@import "variables";
@import "media";

.list-title {
  font-weight: 700;
}

.list-item > a {
  color: inherit;
}

.octopus-logo {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 32'%3E%3Cpath fill='%230d80d8' d='M2.26707,24.13018c2.33913-1.5176,5.088-4.12686,4.04693-7.19646-.56859-1.67341-1.35673-3.106-1.45993-4.914a11.15841,11.15841,0,0,1,.65661-4.50221A11.295,11.295,0,0,1,19.48474.85526c5.22155,1.57931,8.81219,7.69524,6.64911,13.02707-1.24848,3.07466-1.808,5.44818.97632,7.84093.75475.64852,2.588,1.61877,2.57992,2.77013-.00911,1.50647-2.934-.32072-3.26486-.58276.37232.65662,4.06413,4.54066,1.71489,4.81686-2.16309.25395-4.07324-2.79339-5.37534-4.10157-2.18433-2.19748-1.80392,2.66389-1.814,3.66854-.01518,1.5864-1.12808,4.79966-3.11917,2.70639-1.64609-1.728-1.02387-4.485-2.16814-6.40326-1.25354-2.10238-3.33872,2.10238-3.85976,2.87231-.58175.86-3.49554,5.02123-4.654,2.80351-.9399-1.79987.56252-4.61957,1.3021-6.24947-.26912.59085-2.18028,1.465-2.73775,1.7503a7.37091,7.37091,0,0,1-3.95992.92068c-2.934-.21246-.68393-1.78369.51194-2.56171h0Z'/%3E%3C/svg%3E");
  min-width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}
