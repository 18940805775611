@import "../fonts/Roboto/Roboto";
@import "../fonts/fontoctopus/fontoctopus";

@monospace-font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
@monospace-font-size: 1rem;

.monospace {
  font-family: @monospace-font-family;
  line-height: 1.2rem;
}
