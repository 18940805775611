@import "variables";
@import "media";

h1 {
  font-size: 1.625rem; //26px
  font-weight: @normal;
  color: var(--colorTextPrimary);
  @media (max-width: @screen-sm) {
    font-size: 1.4375rem;
  }
}

h2 {
  font-size: 1.25rem; //20px
  font-weight: @normal;
  color: var(--colorTextPrimary);
  @media (max-width: @screen-sm) {
    font-size: 1.0625rem;
  }
}

h3 {
  font-size: 1.125rem; //18px
  font-weight: @normal;
  color: var(--colorTextPrimary);
  @media (max-width: @screen-sm) {
    font-size: 1.0625rem;
  }
}

h4 {
  font-size: 1rem; //16px
  font-weight: @semibold;
  margin: 1rem 0 0.5rem 0;
  color: var(--colorTextPrimary);
}

h5 {
  font-size: 0.8125rem; //12px
  font-weight: @normal;
  margin: 1rem 0 0.5rem 0;
  color: var(--colorTextPrimary);
}

hr {
  display: block;
  height: 0.0625rem;
  border: 0;
  border-top: solid 0.0625rem var(--colorBorderPrimary);
  margin: 1em 0;
  padding: 0;
}

a {
  color: var(--colorTextLink);
}

a:hover {
  color: var(--colorButtonTextLinkHover);
}

p {
  margin: 0.75rem 0;
  //font-size: 14/16rem;
  color: var(--colorTextPrimary);
}

ul {
  color: var(--colorTextPrimary);
}

ol {
  color: var(--colorTextPrimary);
}

// https://stackoverflow.com/questions/2920306/google-chrome-form-autofill-and-its-yellow-background
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}

// https://stackoverflow.com/questions/14007655/remove-ie10s-clear-field-x-button-on-certain-inputs
input::-ms-clear {
  width: 0;
  height: 0;
}

small {
  font-size: 14/16rem;
  line-height: 19/16rem;
}

.secondary {
  color: var(--colorTextSecondary);
}
