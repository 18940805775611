html {
  font-size: 100%;
  color: var(--colorTextPrimary);
}

body {
  font-size: 1rem;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  overflow: auto;
  margin: 0;
  padding: 0;
  background: var(--colorBackgroundSecondaryDefault);
  letter-spacing: 0.25px;
  line-height: 1.25rem;
}

// Links
a {
  text-decoration: none;
}

// Lists
ul,
ol {
  list-style-type: none;
  padding: 0;
}
