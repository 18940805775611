@import "../../resources/styles/media";
@import "../../resources/styles/text";

.section {
  flex-grow: 1;
}

.sectionHeader {
  padding: 0 1rem;
  margin: 0.5rem 0;
}

.sectionBody {
  padding: 0 1rem;
  color: var(--colorMenuListTextPrimary);
  font-size: 14/16rem;

  p {
    color: var(--colorTextPrimary);
  }
}

.sectionNoHeader {
  margin: 0.5rem 0;
  width: 100%; // ie11 needs this
}
